var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Oblia contatto ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" "+_vm._s(_vm.$t('general.cancel'))+" ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"disabled":_vm.loading,"loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v(" "+_vm._s(_vm.$t('general.save'))+" ")])]},proxy:true}]),model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('ValidationObserver',{ref:"forgottenForm"},[_c('VsSelect',{staticClass:"vs-mb-4",attrs:{"label":"Oblia per","options":[
                {
                    label: _vm.$t('lists.suppression.list.emailAddress'),
                    value: 'email',
                },
                {
                    label: _vm.$t('lists.suppression.list.mobile'),
                    value: 'sms',
                } ]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}}),(_vm.type === 'email')?_c('ValidationProvider',{attrs:{"vid":"email_address","name":_vm.$t('lists.suppression.list.emailAddress'),"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsInput',{attrs:{"errorMessage":errors[0],"error":errors.length > 0,"label":_vm.$t('lists.suppression.list.emailAddress')},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})]}}],null,false,2749068690)}):_vm._e(),(_vm.type === 'sms')?_c('ValidationProvider',{attrs:{"vid":"mobile","name":_vm.$t('lists.suppression.list.mobile'),"rules":"required|libphoneNumberMobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('VsPhoneInput',{attrs:{"errorMessage":errors[0],"error":errors.length > 0,"label":_vm.$t('lists.suppression.list.mobile')},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})]}}],null,false,1059670164)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
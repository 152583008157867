var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsContainer',[_c('VsSectionHeader',{staticClass:"vs-mb-4 sm:vs-mb-8",attrs:{"singleLine":"","heading":"Lista contatti obliati"},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('VsButton',{attrs:{"size":"large"},on:{"click":function($event){return _vm.$refs.forgottenModal.openModal()}}},[_vm._v(" Oblia contatto ")])]},proxy:true}])}),_c('div',[_c('VsTable',{staticClass:"vs-relative",attrs:{"loading":_vm.loading,"items":_vm.forgottens,"headers":[
                {
                    id: 'value',
                    key: 'value',
                    label: 'Contatto',
                    visible: true,
                },
                {
                    id: 'created_at',
                    key: 'created_at',
                    label: 'Data',
                    formatter: _vm.$options.filters.formatDate,
                    visible: true,
                } ]}}),_c('VsPagination',{staticClass:"vs-mt-8",attrs:{"totalItems":_vm.total,"filterable":"","expand":"","currentPage":_vm.pagination.page,"itemsPerPage":_vm.pagination.itemsPerPage},model:{value:(_vm.pagination.page),callback:function ($$v) {_vm.$set(_vm.pagination, "page", $$v)},expression:"pagination.page"}})],1),_c('VsForgottenModal',{ref:"forgottenModal",on:{"forgotten-created":_vm.getForgottens}}),(_vm.loading)?_c('VsLoader',{attrs:{"overlay":""}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
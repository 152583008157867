























































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import { UserModule } from '@/store/modules/user'
import VsTable from '@/components/VsTable/Index.vue'
import { getForgottens } from '@/api/consoleApi/oblivion'
import VsForgottenModal from '@/modules/lists/components/VsForgottenModal/Index.vue'

@Component({
    name: 'ForgottensListIndex',
    components: {
        VsContainer,
        VsTable,
        VsSectionHeader,
        VsForgottenModal,
        VsLoader,
    },
})
export default class extends Vue {
    private loading = false
    private forgottens = []
    total = 0
    $refs: any

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    get user () {
        return UserModule.user
    }

    @Watch('pagination', { immediate: true, deep: true })
    async getForgottens () {
        try {
            const resp = await getForgottens(this.buildParams())
            this.forgottens = resp.data.data
            this.total = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.forgottens = []
            this.total = 0
        }
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
        }

        return params
    }
}

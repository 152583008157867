














































































import { Component, Vue } from 'vue-property-decorator'
import { addForgotten } from '@/api/consoleApi/oblivion'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsForgottenModal',
})
export default class extends Vue {
    private show = false
    private loading = false
    private value = ''
    private type = 'email'
    $refs: any

    public async openModal () {
        this.loading = false
        this.value = ''
        this.type = 'email'
        this.show = true
    }

    public closeModal () {
        this.show = false
    }

    private async handleSubmit () {
        const success = await this.$refs.forgottenForm.validate()
        if (!success) return
        this.loading = true
        try {
            await addForgotten({ type: this.type, value: this.value })
            this.$emit('forgotten-created')
            this.$root.$vsToast({
                heading: 'Contatto obliato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.closeModal()
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante l\'oblio del contatto',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
        }
        this.loading = false
    }
}

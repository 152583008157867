import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const getForgottens = (params?: any, cancelToken?: any) =>
    serviceConsoleApi.get(`/forgottens`, {
        cancelToken,
        params,
    })

export const addForgotten = (data: any, params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/forgottens`,
        data,
        {
            params,
            cancelToken,
        },
    )
